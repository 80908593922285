<template>
    <div>
        <smallbutton @click="Start()" :toplabel="clockInLabel" :buttonclass="buttonclass"/>
        <!-- ACCT NOTE MODAL -->
          <actionmodalwide ref="mymodal"> 
            <template v-slot:body>
                <label class="titlelabel">{{clockInLabel}}</label>
                <br>
                
                <label class="entryLabel" for="">Select Location</label>
                <optionbutton :itemList="locationList" @select="selectLocation" :initialSelectedId="locationId"/>
                <div class="leftjustified" v-if="locationId>0">
                    <label class="entryLabel" for="">Select Course</label>
                    <optionbutton :itemList="courseList" @select="selectCourse"/>
                </div>
                
                
                
                
                <div class="leftjustified" v-if="courseId>0">
                    <div v-if="ProcedureTypeList.length>1">
                        <label class="entryLabel" for="">Select Training Category</label>
                        <optionbutton :itemList="ProcedureTypeList" @select="selectProcedureType"/>
                    </div>
                    
        

                    <label class="entryLabel" for="">Select Training</label>
                    <optionbutton :itemList="procedureList" @select="selectprocedure"/>


                    
                    <div class="">
                        <div class="horizFlexStretch">
                            <div>
                                <label class="entryLabel" for="">Select Students</label>
                                <button @click="selectAll" class="left5">Select All</button>
                                <button @click="clearAll" class="left5">Clear All</button>
                            </div>

                            <div class="horizFlex" v-if="showCheckinOverride">
                                <input type="checkbox" v-model="showAllStudents"  @change="selectOverride"/>
                                <p class="checklabel">Show All Students - Override</p>
                            </div>
                        </div>


                        
                        
                    </div>
                    <div class="horizFlex wrap" v-if="!sortStudentsByStartDate">
                        <!-- regular student list -->
                        <div v-for="s in studentList" :key="s.id" :value="s.id" class="checkrecord" >
                            <input class="" type="checkbox" v-model="s.selected" >
                            <p class="checklabel">{{s.name}}</p>
                        </div>

                        
                    </div>
                    <div class="" v-if="sortStudentsByStartDate">
                        <!-- students by start date -->
                        <div v-for="d in startDateList" :key="d.dt" :value="d.dt" v-if="studentList.length>0" >
                            <div>
                                <label class="bold">{{ formatDate(d.dt) }}</label>
                            </div>
                            
                            <br>
                            <div class="horizFlex wrap">
                                <div v-for="s in studentListbydate(d.dt)" :key="s.id" :value="s.id" class="checkrecord" >
                                    <input class="" type="checkbox" v-model="s.selected" >
                                    <p class="checklabel">{{s.name}}</p>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                

                    <label class="entryLabel" for="" v-if="showTrucks">Select Truck</label>
                    <optionbutton :itemList="truckList" @select="selectTruck" v-if="showTrucks"/>

                    <label class="entryLabel" for="" v-if="allowEnterTruckNumber">Truck #</label>
                    <input v-if="allowEnterTruckNumber" v-model="truckNumber" :required="requireTruckNumber"/>

                    <label class="entryLabel" for="" v-if="allowEnterTrailerNumber">Trailer #</label>
                    <input v-if="allowEnterTrailerNumber" v-model="trailerNumber" :required="allowEnterTrailerNumber"/>

                    <label class="entryLabel" for="" v-if="allowEnterRouteNumber">Route #</label>
                    <input v-if="allowEnterRouteNumber" v-model="routeNumber" :required="allowEnterRouteNumber"/>

                    

                    <!-- <label class="entryLabel" for="" v-if="allowEnterMiles">Miles</label>
                    <input v-if="allowEnterMiles" v-model="miles" :required="allowEnterMiles"/> -->

                    
                </div>
                
    
                
                <div>
                    <smallbutton  @click="save()" :toplabel="clockInButtonLabel" buttonclass="buttonprimary extrawidebutton"/>
                    <smallbutton  @click="cancel()" toplabel="Cancel" buttonclass="buttonprimary widebutton"/>
                </div>
                <p class="responsemessage">{{ responseMessage }}</p>
            </template>
          </actionmodalwide>
        </div>
    </template>
    
    <script>
    import {mapFields} from 'vuex-map-fields'
    import datamethods from '../../services/datamethods.js'
    import format from '../../format.js'
    import smallbutton from '../controls/button.vue'
    import actionmodalwide from '../controls/actionmodalwide.vue'
   import optionbutton from '../controls/optionbutton.vue'
    export default {
        name:'clockin',
        components:{
            smallbutton,
            actionmodalwide,
            optionbutton

        },
        props:{
            buttonclass:{
                type:String,
                default:"button buttonprimary"
            },
           
    
        },
        mixins:[datamethods,format],
        data(){
            

            return{
                startDateList:[],

                inSave:false,
                responseMessage:null,

                locationId:0,
                courseId:-1,
                procedureTypeId:0,
                procedureId:-1,
                truckId:-1,

                courseList:[],
                studentList:[],
                prevStudentList:[],

                ProcedureTypeList:[],
                procedureList:[],
                truckList:[],
                showTrucks:false,
                allowEnterTruckNumber:false,
                allowEnterRouteNumber:false,
                allowEnterTrailerNumber:false,
                allowEnterMiles:false,
                truckNumber:null,
                routeNumber:null,
                trailerNumber:null,
                miles:null,
                showAllStudents:false

            }
        },
        computed:{
            ...mapFields([
                 'isManualTime',
                 'locationList',
                 'loggedInUser',
                 'online',
                 'pleaseWait',
                 'lastAddedEvalId',
                 'lastAddedBatchNumber'
            ]),
           clockInLabel(){
                if(this.isManualTime){return "Create Evaluations"}
                else{return "Start Training"}
           },
           sortStudentsByStartDate(){
            
            if(window.SORT_STUDENTS_BY_STARTDATE_AT_CLOCKIN==undefined){return false}
            else{return window.SORT_STUDENTS_BY_STARTDATE_AT_CLOCKIN}
           },
           
           clockInButtonLabel(){
            if(this.isManualTime){return "Create Evaluations"}
                else{return "Create Evaluations"}
           },
           studentSelected(){
                var sel=false
                this.studentList.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                    if(arr[index].selected){
                        sel=true
                    }
                }
                return sel
           },
           
           requireTruckNumber(){
            if(window.REQUIRE_TRUCK_NUMBER==undefined){return false}
            else{return window.REQUIRE_TRUCK_NUMBER}
           },
           showCheckinOverride(){
            
            if(window.SHOW_CHECKIN_OVERRIDE==undefined){return false}
            else{return window.SHOW_CHECKIN_OVERRIDE}
           }
        },
        methods:{
            formatDate(dt){
                return this.$options.filters.formatDate(dt)
            },
            studentListbydate(d){
                var outlist=[]

                this.studentList.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                    let d1 = arr[index].startDate1
                    let d2 = d
                    console.log(d1 + ' ' + d2 )
                    if(d1==d2){
                        outlist.push(arr[index])
                    }
                }
                console.log("out " + outlist.length)
                return outlist
            },
            selectOverride(){
                this.getStudents()
            },
            selectAll(){
                this.studentList.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                    arr[index].selected=true
                }
            },
            clearAll(){
                this.studentList.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                    arr[index].selected=false
                }
            },
            selectLocation(id){
                this.locationId=id
                this.studentList=[]
                this.getCourses();
                //this.getStudents();
                this.getTrucks();
            },
            selectCourse(id){
                this.studentList=[]
                this.procedureList=[]
                this.allowEnterTruckNumber=false
                this.allowEnterRouteNumber=false
                this.allowEnterMiles=false
                this.allowEnterTrailerNumber=false
                this.courseId=id
                this.getProcedureTypes();
            },
            selectProcedureType(id){
                console.log('seltype ' + id)
                this.procedureTypeId=id
                this.getProcedures()
            },
            selectprocedure(id){
                var show=true;
                var entertruck=false;
                var enterroute=false;
                var entertrailer=false;
                var entermiles=false;
                this.procedureId = id
                //determin if we show station
                this.procedureList.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                    let comp=arr[index]
                    if(comp.id==id){
                        show = comp.allowSelectLocationStation
                        entertruck=comp.allowEnterTruckNumber
                        enterroute=comp.allowEnterRouteNumber
                        entertrailer=comp.allowEnterTrailerNumber
                        entermiles=comp.allowEnterMiles
                    }
                }
                this.allowEnterTruckNumber=entertruck;
                this.allowEnterRouteNumber=enterroute;
                this.allowEnterTrailerNumber=entertrailer;
                this.allowEnterMiles=entermiles;
                this.showTrucks=show && !entertruck;
                this.getStudents();
            },
            selectTruck(id,name){
                this.truckId=id
                this.truckNumber=name
            },
           Start(){
            if(!this.online){this.$emit('offline'); return;}
                this.responseMessage=null    
                this.truckNumber=null
                this.routeNumber=null
                this.trailerNumber=null
                this.locationId=this.loggedInUser.LocationId
                this.showAllStudents=false
                 this.selectLocation(this.locationId);

                this.$refs.mymodal.openModal()
        
           },
           
            cancel(){
                this.pleaseWait=false; 
                this.$refs.mymodal.closeModal()
               // this.$emit('refresh')
            },
        
            async getCourses(){
                let req = {
                    LocationID:this.locationId
                }
                this.$store.dispatch('beforeAPI');
                var response = await this.callAPI(req,'courses')
                //handle response
                if(response.Successful){
                    this.courseList=response.TimeEventList
                    this.courseId=-1
                }else{
                    this.responseMessage = response.Message
                }
                this.pleaseWait=false; 
          },
          async getStudents(){
            this.savePreviousCheckedStudents()
                let req = {
                    locationId:this.locationId,
                    RestrictToNotClockedInNow:true,
                    procedureSequenceId:this.procedureId,
                    procedureTypeId:this.procedureTypeId,
                    isManual:this.isManualTime,
                    override_AllRules:this.showAllStudents,
                    sortByStartDate:this.sortStudentsByStartDate
                }
                this.$store.dispatch('beforeAPI');
                var response = await this.callAPI(req,'studentsforinout')
                //handle response
                if(response.Successful){
                    this.studentList=response.PersonList
                    this.startDateList=response.startDateList
                    this.restorePreviousCheckedStudents()
                }else{
                    this.responseMessage = response.Message
                }
                this.pleaseWait=false; 
          },
          savePreviousCheckedStudents(){
            var checks=[]
            this.studentList.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                    if(arr[index].selected){
                        console.log(arr[index].id + ' ' + ' is selected')
                        checks.push(arr[index])
                    }
                }
                this.prevStudentList=checks
          },
          restorePreviousCheckedStudents(){
            var tmpStudentList = this.studentList
            this.prevStudentList.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                    let stuid=arr[index].id
                    tmpStudentList.forEach(myFunction3)
                    function myFunction3(item, index, arr) {
                        let i = arr[index]
                        if(i.id==stuid){
                            i.selected=true
                        }
                        
                    }
                }
                this.studentList= tmpStudentList
          },
          async getProcedureTypes(){
                let req = {
                    id:this.courseId
                }
                this.$store.dispatch('beforeAPI');
                var response = await this.callAPI(req,'proceduretypes')
                //handle response
                if(response.Successful){
                    this.ProcedureTypeList=response.ProcedureTypeList
                    if(this.ProcedureTypeList.length==1){
                        this.selectProcedureType(this.ProcedureTypeList[0].id)
                    }
                    
                }else{
                    this.responseMessage = response.Message
                }
                this.pleaseWait=false; 
          },
          async getProcedures(){
                let req = {
                    id:this.procedureTypeId
                }
                this.$store.dispatch('beforeAPI');
                var response = await this.callAPI(req,'procedures')
                //handle response
                if(response.Successful){
                    this.procedureList=response.ProcedureList
                    this.procedureId=-1
                }else{
                    this.responseMessage = response.Message
                }
                this.pleaseWait=false; 
          },
          async getTrucks(){
                let req = {
                    id:this.locationId
                }
                this.$store.dispatch('beforeAPI');
                var response = await this.callAPI(req,'locationstations')
                //handle response
                if(response.Successful){
                    this.truckList=response.GradeLocationStations
                    
                }else{
                    this.responseMessage = response.Message
                }
                this.pleaseWait=false; 
          },
          async save(){
                if(this.inSave){return}

                
                if(this.routeNumber=='')    {this.routeNumber=null}
                if(this.truckNumber=='')    {this.truckNumber=null}
                if(this.trailerNumber=='')    {this.trailerNumber=null}
                if(this.courseId==-1){this.responseMessage='Please select a Course'; return}
                if(this.procedureId==-1){this.responseMessage='Please select Training'; return}
                if(this.truckId==-1 && this.showTrucks && this.truckList.length>0){this.responseMessage='Please select a Truck'; return}
                if(!this.studentSelected){this.responseMessage="Please select at least one student"; return}
                if(this.truckNumber==null && this.requireTruckNumber && this.allowEnterTruckNumber){this.responseMessage="Please enter a truck number";return}
                if(this.routeNumber==null && this.allowEnterRouteNumber){this.responseMessage="Please enter a route number";return}
                if(this.trailerNumber==null && this.allowEnterTrailerNumber){this.responseMessage="Please enter a trailer number";return}
                // if(this.miles==null && this.allowEnterMiles){this.responseMessage="Please enter miles";return}
                
                this.inSave=true
                
                let req = {
                    locationId:this.locationId,
                    courseId:this.courseId,
                    procedureId:this.procedureId,
                    locationStationId:this.truckId,
                    instructorId:this.loggedInUser.user_personId,
                    studentList:this.studentList,
                    truckNumber:this.truckNumber,
                    routeNumber:this.routeNumber,
                    trailerNumber:this.trailerNumber,
                    miles:this.miles
                }



                this.$store.dispatch('beforeAPI');
                var response
                if(this.isManualTime){
                    response = await this.callAPI(req,'creategradesheet')
                }else{
                    response = await this.callAPI(req,'clockinandcreategradesheet')
                }
                
                //handle response
                if(response.Successful){

                    this.lastAddedEvalId=response.lastAddedEvalId
                    this.lastAddedBatchNumber=response.lastAddedBatchNumber
                    this.pleaseWait=false; 
                    this.inSave=false
                    console.log('loc refresh ' + this.locationId)
                    this.$emit('refreshafterin',this.locationId)
                    this.$refs.mymodal.closeModal()
                    
                }else{
                    this.inSave=false
                    this.responseMessage = response.Message
                }
                this.pleaseWait=false; 
                this.inSave=false
          },
        }
    }
    </script>
    
    <style>
    .checkrecord{
        display:flex;
        flex-direction: row;
        margin: 0px 0px 0px 20px;
    }
    .gradegrid{
        display:grid;
        grid-template-columns: 1fr 5fr;
        text-align:left;
    }
    
    .updatebutton{
        height:30px;
        width:150px;
        border-radius: 5px;
        padding:3px;
        margin:0px 0px 10px 0px;
    }
    .checklabel{
        margin:5px;
    }
    </style>
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    