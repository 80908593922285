<template>
    <div>
       
        <!-- MODAL -->
          <actionmodalwide ref="mymodal"> 
            <template v-slot:body>
                <label class="titlelabel">Time Conflicts</label>
                <br>
                <label :class="textclass">The following conflicting times have already been submitted for this date. Do you want to submit your grades and time? </label>
                <br>
                <div v-for="c in timeConflictList" :key="c.id" :value="c.id" class="timegrid">
                    <label>{{ c.studentName }}</label>
                    <label>{{ c.instructorName }}</label>
                    <label>{{ c.evalStartTime }}</label>
                    <label>{{ c.evalEndTime }}</label>
                    <label>{{ c.jobName }}</label>
                </div>
                <div>
                    <smallbutton  @click="CloseWithYes" toplabel="Yes"/>
                    <smallbutton  @click="$refs.mymodal.closeModal()" :toplabel="noLabel"/>
                </div>
                <div>
                  <label class="responsemessage">{{responseMessage}}</label>
                </div>
            </template>
          </actionmodalwide>
        </div>
    </template>
    
    <script>
    import smallbutton from '../controls/button.vue'
    import actionmodalwide from '../controls/actionmodalwide.vue'

    export default {
        name:'confirmtimeconflict',
        components:{
            smallbutton,
            actionmodalwide,

        },
        props:{

  
            redText:{
                type:Boolean,
                default:false,
            },
            noLabel:{
                type:String,
                default:'No'
            },
            timeConflictList:{
                type:Array
            }
        },
        data(){
            return{
                responseMessage:''
            }
        },
        computed:{
            buttonText(){
                if (this.buttonLabel==""){return this.title}
                else{return this.buttonLabel}
            },
            textclass(){
                if(this.redText){return "responsemessage"}
                else{return ""}
            }
        },
        methods:{
            
            Show(){
                

                this.$refs.mymodal.openModal()
            },
            async CloseWithYes(){
                this.$emit('yes')
                this.$refs.mymodal.closeModal()
            }
        }
    }
    </script>
    
    <style>
    .timegrid{
        display:grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr ;
        border:1px solid gray;
        padding: 10px;
    }
    </style>